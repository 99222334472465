
import { createUserAccount, userSignedIn } from '@/firebase'
import { reactive, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default {
  setup (): any {
    const store = useStore()
    const route = useRoute()
    const slug = route.params.slug.toString()
    const userTermsAccepted = computed(() => store.getters.getUserTermsAccepted)
    const form = reactive({
      email: '',
      name: '',
      password: '',
      project: ''
    })
    const useSignup = async () => {
      store.dispatch('findUserRecordId', form.email)
    }
    const finalizeSignin = async () => {
      await createUserAccount({ ...form })
      form.email = ''
      form.password = ''
      form.name = ''
      form.project = slug
    }
    // watch(userSignedIn, (newValue) => {
    //   console.log('Signed In', userSignedIn)
    //   if (newValue) {
    //     router.push('/' + slug)
    //   }
    // })
    // watch(userAuthenticated, (newValue) => {
    //   console.log('user authenticated')
    //   if (newValue) {
    //     finalizeSignin()
    //   } else {
    //     console.log('NOT AUTHENTICATED')
    //   }
    // })
    watch(userTermsAccepted, (newValue) => {
      if (newValue) {
        finalizeSignin()
      }
    })
    return {
      form,
      userSignedIn,
      useSignup,
      slug,
      baseName: computed(() => store.getters.getBaseName)
    }
  }
}
