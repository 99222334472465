
import { userSignedIn } from '@/firebase'
import { computed, watch, ref } from 'vue'
import AccountCreate from '@/components/AccountCreate.vue'
import { useStore } from 'vuex'
import { InfoDataObj } from '@/store/pages/types'
import { useRoute, useRouter } from 'vue-router'
import Markdown from 'vue3-markdown-it'

export default {
  name: 'Create Account',
  components: { AccountCreate, Markdown },
  setup (): any {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const slug = route.params.slug.toString()
    const termsState = ref(false)
    const termsError = ref(false)
    const userAuthenticated = computed(() => store.getters.getUserAuthenticated)
    const userTermsAccepted = computed(() => store.getters.getUserTermsAccepted)
    const termsLoaded = computed(() => store.getters.getInfoLoaded)
    const infoData = computed(() => store.getters.getInfoData)
    const termsData = computed(() => infoData.value.filter((item: InfoDataObj) => item.title === 'Terms and Conditions'))

    const returnToLogin = () => {
      store.dispatch('resetUserAuthenticated')
    }
    const handleTermsState = () => {
      termsState.value = true
    }
    const handleSubmit = () => {
      if (termsState.value) {
        store.dispatch('acceptTerms')
      } else {
        termsError.value = true
      }
    }
    watch(userAuthenticated, (newValue) => {
      if (newValue) {
        store.dispatch('getInfoPagesData')
      }
    })
    watch(userSignedIn, (newValue) => {
      // console.log('Signed In', userSignedIn)
      if (newValue) {
        router.push('/' + slug)
      }
    })
    return {
      slug,
      userAuthenticated,
      userTermsAccepted,
      termsState,
      termsError,
      termsLoaded,
      termsData,
      returnToLogin,
      handleTermsState,
      handleSubmit
    }
  }
}
